import { devtools, persist } from "zustand/middleware";
import { create } from "zustand";
import { ProfileI } from "@/models/ProfileI";

interface AuthState {
  user: ProfileI;
  setUser: (data: ProfileI) => void;
  logout: () => void;
}

export const useAuth = create<AuthState>()(
  devtools(
    persist(
      (set) => ({
        user: null,
        setUser: (data) => set(() => ({ user: data })),
        logout: () => set(() => ({ user: null })),
      }),
      {
        name: "auth-storage",
      }
    )
  )
);
