// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ixlcxDmj{z-index:201;position:fixed;top:0;left:0;width:100%;padding:12px 40px 12px 16px;background:#0052ff;text-align:center;font-size:14px;font-weight:500;color:#fff !important}.ixlcxDmj[data-type=success]{background:#62c751}.ixlcxDmj[data-type=error]{background:#f5353d}.ixlcxDmj button{position:absolute;right:16px;top:50%;transform:translateY(-50%);display:flex;align-items:center;justify-content:center}.ixlcxDmj button svg{width:18px;height:18px}`, "",{"version":3,"sources":["webpack://./src/styles/components/Alert.module.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AACA,UACE,WAAA,CACA,cAAA,CACA,KAAA,CACA,MAAA,CAEA,UAAA,CACA,2BAAA,CAEA,kBCVc,CDWd,iBAAA,CACA,cAAA,CACA,eAAA,CACA,qBAAA,CAEA,6BACE,kBCTU,CDYZ,2BACE,kBCdQ,CDiBV,iBACE,iBAAA,CACA,UAAA,CACA,OAAA,CACA,0BAAA,CAEA,YAAA,CACA,kBAAA,CACA,sBAAA,CAEA,qBACE,UAAA,CACA,WAAA","sourcesContent":["@import \"@/styles/colors\";\n.root {\n  z-index: 201;\n  position: fixed;\n  top: 0;\n  left: 0;\n\n  width: 100%;\n  padding: 12px 40px 12px 16px;\n\n  background: $color-primary;\n  text-align: center;\n  font-size: 14px;\n  font-weight: 500;\n  color: #fff !important;\n\n  &[data-type='success'] {\n    background: $color-green;\n  }\n\n  &[data-type='error'] {\n    background: $color-red;\n  }\n\n  button {\n    position: absolute;\n    right: 16px;\n    top: 50%;\n    transform: translateY(-50%);\n\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    svg {\n      width: 18px;\n      height: 18px;\n    }\n  }\n}\n","$color-primary: #0052FF;\n$color-secondary: #2982ea;\n$color-contrast: #c1f2ff;\n$color-contrast-secondary: #83e4ff;\n$color-text: #000000;\n$color-text-secondary: #8a8a8a;\n$color-black: #000000;\n$color-red: #f5353d;\n$color-green: #62c751;\n$color-bg: #ffffff;\n$color-bg-secondary: #f6f8ff;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `ixlcxDmj`
};
export default ___CSS_LOADER_EXPORT___;
