import React, { FC } from "react";
import cls from "@/styles/components/App.module.scss";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import "@/styles/base.scss";
import { Sidebar } from "@/components/Sidebar/Sidebar";
import cn from "classnames";
import { useAuth } from "@/store/auth";
import { Alert } from "@/components/Alert/Alert";

export const App: FC = observer(() => {
  const { pathname } = useLocation();
  const showSidebar = pathname !== "/login";
  const { user } = useAuth();

  return (
    <div className={cls.wrapper}>
      {showSidebar && <Sidebar />}
      <div className={cn(cls.container, { [cls.showSidebar]: showSidebar })}>
        {showSidebar && !user && <Navigate to="/login" replace />}
        <Outlet />
      </div>
      <Alert />
    </div>
  );
});
