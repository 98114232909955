// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.D8YrOZUH{display:flex;flex-direction:row}.wN4juIk9{height:100vh;padding:1rem 2rem;overflow-y:auto;flex:1}.U6gzMwT0{margin-left:17rem}`, "",{"version":3,"sources":["webpack://./src/styles/components/App.module.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAGA,UACE,YAAA,CACA,kBAAA,CAGF,UACE,YAAA,CACA,iBAAA,CACA,eAAA,CAEA,MAAA,CAGF,UACE,iBCjBc","sourcesContent":["@import \"@/styles/colors\";\n@import \"@/styles/variables\";\n\n.wrapper {\n  display: flex;\n  flex-direction: row;\n}\n\n.container {\n  height: 100vh;\n  padding: 1rem 2rem;\n  overflow-y: auto;\n\n  flex: 1;\n}\n\n.showSidebar {\n  margin-left: $sidebar-width;\n}\n","$sidebar-width: 17rem;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `D8YrOZUH`,
	"container": `wN4juIk9`,
	"showSidebar": `U6gzMwT0`
};
export default ___CSS_LOADER_EXPORT___;
