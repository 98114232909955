// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VkpvFUn1{pointer-events:none;opacity:0}.vke_85NB{pointer-events:auto;opacity:1}.RRxERbJj{pointer-events:auto;opacity:1}.R3w4xtgq{pointer-events:none;opacity:0}.vke_85NB,.R3w4xtgq{transition:.2s ease-in-out}`, "",{"version":3,"sources":["webpack://./src/styles/extra/FadeTransition.module.scss"],"names":[],"mappings":"AAAA,UACE,mBAAA,CACA,SAAA,CAGF,UACE,mBAAA,CACA,SAAA,CAGF,UACE,mBAAA,CACA,SAAA,CAGF,UACE,mBAAA,CACA,SAAA,CAGF,oBAEE,0BAAA","sourcesContent":[".enter {\n  pointer-events: none;\n  opacity: 0;\n}\n\n.enterActive {\n  pointer-events: auto;\n  opacity: 1;\n}\n\n.exit {\n  pointer-events: auto;\n  opacity: 1;\n}\n\n.exitActive {\n  pointer-events: none;\n  opacity: 0;\n}\n\n.enterActive,\n.exitActive {\n  transition: 0.2s ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"enter": `VkpvFUn1`,
	"enterActive": `vke_85NB`,
	"exit": `RRxERbJj`,
	"exitActive": `R3w4xtgq`
};
export default ___CSS_LOADER_EXPORT___;
