import React, { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { routes } from "@/components/App/routes";
import { observer } from "mobx-react-lite";
import cls from "@/styles/components/Sidebar.module.scss";
import cn from "classnames";
import { Icon } from "@/ui/Icons/Icon";

export const Sidebar: FC = observer(() => {
  const { pathname } = useLocation();

  return (
    <div className={cls.root}>
      <div className={cls.left}>
        <div className={cls.logo}>JobSwipe</div>
        <div className="divider" />
        <div className={cls.links}>
          {routes.map((route) => {
            return (
              <Link
                to={route.path}
                key={route.path}
                className={cn(cls.link, {
                  [cls.active]:
                    route.path === pathname ||
                    (route.path !== "/" && pathname.includes(route.path)),
                })}
              >
                <Icon name={route.icon} /> {route.name}
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
});
